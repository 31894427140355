import React, { useState, memo } from "react";
import { Pages } from "../Pages";
import { partTranslation } from "../translations";

import { useTranslation, Translation } from "../../../hooks/translation";
import { TranslatedText } from "../types";
import {
  Typography,
  Chip,
  IconButton,
  Collapse,
  Tooltip,
} from "@material-ui/core";

import HelpIcon from "@material-ui/icons/HelpOutline";
import { CancelHelpIcon } from "./CancelHelpIcon";

const translations: Translation<Record<
  "completed" | "showHelp" | "hideHelp",
  string
>> = {
  en: {
    completed: "completed",
    showHelp: "Show Help",
    hideHelp: "Hide Help",
  },
  de: {
    completed: "beantwortet",
    showHelp: "Hilfe anzeigen",
    hideHelp: "Hilfe ausblenden",
  },
};

interface Props {
  part: Pages.Part1 | Pages.Part2 | Pages.Part3;
  finished: number;
  total: number;
  descriptionTranslation: TranslatedText;
  currentIndex: number;
}

const partNrMapping = { [Pages.Part1]: 1, [Pages.Part2]: 2, [Pages.Part3]: 3 };

function PartTitleComponent({
  part,
  finished,
  total,
  descriptionTranslation,
  currentIndex,
}: Props) {
  const translatedPart = useTranslation(partTranslation);
  const description = useTranslation(descriptionTranslation);
  const i18n = useTranslation(translations);

  const [showDescription, setShowDescription] = useState(true);

  const toggleDescription = () => setShowDescription((show) => !show);

  const partNr = partNrMapping[part];

  const percentage = Math.round((finished / total) * 100);

  return (
    <>
      <Typography variant="h4">
        {translatedPart} {partNr}{" "}
        <Tooltip
          title={showDescription ? i18n.hideHelp : i18n.showHelp}
          placement="right"
        >
          <IconButton onClick={toggleDescription}>
            {!showDescription ? <HelpIcon /> : <CancelHelpIcon />}
          </IconButton>
        </Tooltip>
      </Typography>
      <Collapse in={showDescription}>
        <Typography variant="body2" gutterBottom>
          {description}
        </Typography>
      </Collapse>

      <Typography variant="subtitle1" gutterBottom>
        <Chip
          color="primary"
          label={`${currentIndex + 1} / ${total}`}
          variant="outlined"
          size="small"
        />{" "}
        {percentage}% {i18n.completed}
      </Typography>
    </>
  );
}

export const PartTitle = memo(PartTitleComponent);
