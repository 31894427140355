import React from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useStyles } from "./Table.styles";

import { Part1ResultDatum } from "../Test/Part1/types";

import { useLanguage } from "../../hooks/translation";

import { buttonConfigs } from "../Test/Part1/buttonConfig";

function formatValue(value: Part1ResultDatum) {
  return buttonConfigs.find((config) => config.value === value)?.label!;
}

interface Props {
  data: Part1ResultDatum[];
}

export function Part1Table({ data }: Props) {
  const classes = useStyles();

  const { language } = useLanguage();

  return (
    <TableContainer square variant="outlined" component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell>Question Number</TableCell>
            <TableCell align="right">Value</TableCell>
            <TableCell>Text</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((value, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="right">{value}</TableCell>
              <TableCell>{formatValue(value)[language]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
