import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    lineHeight: "1px",
  },
  overlayIcon: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    color: "rgba(255,0,0,0.7)",
  },
}));
