import React from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useStyles } from "./Table.styles";

interface Props {
  data: [number, number][];
}

export function Part2Table({ data }: Props) {
  const classes = useStyles();

  return (
    <TableContainer square variant="outlined" component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell>Question Number</TableCell>
            <TableCell align="right">Most applicable (1-4)</TableCell>
            <TableCell align="right">Least applicable (1-4)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(([mostApplicable, leastApplicable], index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="right">{mostApplicable + 1}</TableCell>
              <TableCell align="right">{leastApplicable + 1}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
