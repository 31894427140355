import React, { useState } from "react";

import { TextField, Typography, Box, Grid } from "@material-ui/core";

import { Base64Data } from "../Test/Finish";
import { calculatePart2 } from "./part2";
import { calculatePart3 } from "./part3";
import { Part1Table } from "./Part1Table";
import { Part2Table } from "./Part2Table";
import { Part3Table } from "./Part3Table";

function useCalculate(input: string) {
  let parsed: Base64Data;
  try {
    const data = atob(input);
    parsed = JSON.parse(data);
  } catch {
    return { success: false } as const;
  }

  const { part1, part2, part3, ...rest } = parsed;

  const resultPart2 = calculatePart2(part2);
  const resultPart3 = calculatePart3(part3);

  return {
    success: true,
    part1,
    part2,
    part3,
    resultPart2,
    resultPart3,
    ...rest,
  } as const;
}

export default function Calculator() {
  // const classes = useStyles();

  const [input, setInput] = useState("");

  const data = useCalculate(input);

  return (
    <Box m={3}>
      <Box mb={2}>
        <TextField
          fullWidth
          variant="filled"
          label="Data"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      </Box>
      {data.success ? (
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography variant="h5">Part 1 data</Typography>
          </Grid>
          <Grid item>
            <Part1Table data={data.part1} />
          </Grid>
          <Grid item>
            <Typography variant="h5">Part 2 result</Typography>
          </Grid>
          <Grid item>
            <pre>{JSON.stringify(data.resultPart2)}</pre>
            <Part2Table data={data.part2} />
          </Grid>
          <Grid item>
            <Typography variant="h5">Part 3 result</Typography>
          </Grid>
          <Grid item>
            <pre>{JSON.stringify(data.resultPart3)}</pre>
            <Part3Table data={data.part3} />
          </Grid>
        </Grid>
      ) : (
        <span>invalid</span>
      )}
    </Box>
  );
}
