import { makeStyles } from "@material-ui/core";
import { primary } from "../../../theme";

export const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    // padding: theme.spacing(0.5),
    background: `linear-gradient(34deg, ${primary[900]} 0%, ${primary[600]} 62%, ${primary.A700} 80%)`,
    transition: "background 0.5s linear",
    "&:hover": {
      background: `linear-gradient(34deg, ${primary[600]} 0%, ${primary[400]} 62%, ${primary.A400} 80%)`,
    },
  },
}));
