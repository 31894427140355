import React, { memo } from "react";
import { QuestionDatum } from "./data";
import { ResultValue } from "./types";
import { buttonConfigs } from "./buttonConfig";
import AnswerButton from "./AnswerButton";
import { useTranslation } from "../../../hooks/translation";
import { useStyles } from "./Question.styles";
import { Typography } from "@material-ui/core";

interface Props {
  question: QuestionDatum;
  index: number;
  onAnswer: (data: ResultValue) => void;
  result?: ResultValue;
}

function Question({ question, onAnswer, result, index }: Props) {
  const classes = useStyles();
  const label = useTranslation(question.label);
  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom align="center">
        {label}
      </Typography>
      <div className={classes.buttons}>
        {buttonConfigs.map((config) => (
          <AnswerButton
            key={config.value}
            onAnswer={onAnswer}
            config={config}
            isSelected={config.value === result}
          />
        ))}
      </div>
    </div>
  );
}

export default memo(Question);
