import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(),
    display: "flex",
    justifyContent: "space-around",
  },
}));
