import React from "react";
import Test from "./pages/Test";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import { MuiThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import Layout from "./components/Layout";
import Calculator from "./pages/Calculator";

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/test">
              <Test />
            </Route>
            <Route path="/calculator">
              <Calculator />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
