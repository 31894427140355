import React, { memo } from "react";
import { PartProps } from "../types";
import { useResult } from "../useData";
import { data, QuestionDatum, description } from "./data";
import Question from "./Question";
import { ResultValue, Part2ResultDatum } from "./types";
import { Pages } from "../Pages";
import { NavigationButtons } from "../components/NavigationButtons";
import { PartTitle } from "../components/PartTitle";

function Part2({ onPartFinish }: PartProps) {
  const {
    current,
    all,
    addResult,
    currentResult,
    allResults,
    totalCount,
    currentIndex,
    ...navigationProps
  } = useResult<ResultValue, QuestionDatum>(data, Pages.Part2);

  const handlePartFinish = () =>
    onPartFinish(all.map((_, index): Part2ResultDatum => allResults[index]));

  const handleResult = (result: ResultValue) => {
    addResult(result);
  };

  return (
    <>
      <PartTitle
        descriptionTranslation={description}
        part={Pages.Part2}
        finished={allResults.length}
        total={totalCount}
        currentIndex={currentIndex}
      />
      <Question
        question={current}
        onAnswer={handleResult}
        result={currentResult}
      />
      <NavigationButtons
        handlePartFinish={handlePartFinish}
        {...navigationProps}
      />
    </>
  );
}

export default memo(Part2);
