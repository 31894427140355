import { TranslatedText } from "./types";

export const partTranslation: TranslatedText = {
  en: "Part",
  de: "Teil",
};

export const introductionTranslation: TranslatedText = {
  en: "Introduction",
  de: "Erklärung",
};

export const finishTranslation: TranslatedText = {
  en: "Finish",
  de: "Ende",
};
