import { TranslatedText } from "../types";

export interface QuestionDatum {
  label: TranslatedText;
}

export const description: TranslatedText = {
  de:
    "Klicke JA an für Sätze, denen du zustimmst und NEIN wenn du nicht zustimmst.",
  en: "Click YES for sentences you agree with, and NO if you don't agree.",
};

export const data: QuestionDatum[] = [
  { label: { de: `Ich bin ein guter Zuhörer.`, en: `I am a good listener.` } },
  {
    label: {
      de: `Ich neige dazu in Gruppen zu führen.`,
      en: `I tend to be leading in groups.`,
    },
  },
  {
    label: {
      de: `Ich stelle andere oft richtig.`,
      en: `I often contradict others.`,
    },
  },
  {
    label: {
      de: `Ich stehe zu den Schwächeren.`,
      en: `I side with the weaker people.`,
    },
  },
  { label: { de: `Ohne Fleiß kein Preis.`, en: `No cross, no crown.` } },
  {
    label: {
      de: `Wenn ich mich in die Ecke gedrängt fühle bei Diskussionen, agiere ich wütend.`,
      en: `If I feel like I'm driven into a corner during an argument, I tend to react angrily.`,
    },
  },
  {
    label: {
      de: `Ich finde den Satz "Respekt ist aller Liebe Grund" zutreffend.`,
      en: `I find the sentence "The foundation of every true love is dignity." applicable.`,
    },
  },
  {
    label: {
      de: `Der Charakter einer Person kann sich nicht ändern, egal wie sehr man es versucht.`,
      en: `The character of a person cannot be changed, no matter how hard you try.`,
    },
  },
  {
    label: {
      de: `Ich neige dazu, in schwierigen Situationen zu leiten.`,
      en: `I tend to take the lead in difficult and messy situations.`,
    },
  },
  {
    label: {
      de: `Ich weiß, wie man andere tröstet.`,
      en: `I know how to comfort others.`,
    },
  },
  {
    label: {
      de: `Öfter als ich es möchte, suche ich den Fehler bei anderen.`,
      en: `More often than I would like, I look for the fault with the others.`,
    },
  },
  {
    label: {
      de: `Die meisten Leute wollen geführt werden.`,
      en: `Most people want to be lead.`,
    },
  },
  {
    label: {
      de: `Ich finde Berufe wertvoll, mit denen anderen geholfen wird.`,
      en: `I consider the professions to be the most valuable in which people are helped.`,
    },
  },
  {
    label: {
      de: `Ich bin verständnisvoll, wenn andere Probleme haben.`,
      en: `I am understanding when others have problems.`,
    },
  },
  {
    label: {
      de: `Ich habe starke Meinungen und ändere sie nicht so leicht.`,
      en: `I have strong convictions and I do not change them easily.`,
    },
  },
  {
    label: {
      de: `Ich belehre andere oft.`,
      en: `I often find myself lecturing others.`,
    },
  },
  {
    label: {
      de: `Es gibt nur eine wirkliche Veränderung, wenn eine starke Person die Sache in die Hand nimmt.`,
      en: `A real change only takes place when a strong person takes a matter in hand.`,
    },
  },
  {
    label: {
      de: `Ich verlasse mich im Leben auf Traditionen und Bewährtes.`,
      en: `In my life I tend to rely on traditions and the tried and tested.`,
    },
  },
  {
    label: {
      de: `Es ärgert mich, wenn Leute bewährte Denkweisen ablehnen und hinterfragen.`,
      en: `I tend to get upset about people who reject and question established and accepted ways of thinking.`,
    },
  },
  {
    label: {
      de: `Ich finde es hilfreich andere auf ihre Fehler aufmerksam zu machen.`,
      en: `In my opinion it is helpful to make others aware of their own mistakes.`,
    },
  },
  {
    label: {
      de: `Ich habe klare Vorstellungen von richtig und falsch.`,
      en: `I have quite clear ideas about what is right and wrong.`,
    },
  },
  {
    label: {
      en: `I think the idea that people should be kind is right.`,
      de: "Ich finde die Idee, dass Menschen human sein sollten, richtig ist.",
    },
  },
  {
    label: {
      de: `Man fragt mich oft um Rat.`,
      en: `People often ask me for advise.`,
    },
  },
  {
    label: {
      de: `Ein Problem, das uns immer begleitet ist, dass es zu wenige gibt die arbeiten und zu viele die bestimmen wollen.`,
      en: `A problem that always seems to accompany us is that there are too few people who work and too many who want to command.`,
    },
  },
  {
    label: {
      de: `Was Hänschen nicht lernt, lernt Hans nimmermehr.`,
      en: `You can't teach an old dog new tricks.`,
    },
  },
  {
    label: {
      de: `Die meisten Leute verstehen nicht, dass man kämpferisch sein muss, um im Geschäftsleben erfolgreich zu sein.`,
      en: `Many people do not realize that you have to be combative in business to be successful.`,
    },
  },
  {
    label: {
      de: `Man muss Leute oft zu Dingen zwingen, die gut für sie sind.`,
      en: `People often have to be pushed to do things that are good for them.`,
    },
  },
  {
    label: {
      de: `Verbrechen sollten stärker bestraft werden.`,
      en: `Violations of laws should be punished more severely.`,
    },
  },
  {
    label: {
      de: `Man hat mich schon einmal auf meinen Befehlston aufmerksam gemacht.`,
      en: `I've been called on my commanding tone before.`,
    },
  },
  {
    label: {
      de: `Ich vertraue meinen Mitmenschen mehr als andere es tun.`,
      en: `I trust my fellow human beings more than others do.`,
    },
  },
  {
    label: {
      de: `Es ist einfacher zu befehlen, als befehlen zu folgen.`,
      en: `I find it easier to command than to follow orders.`,
    },
  },
  {
    label: {
      de: `Das größte Missgeschick ist die Geduld zu verlieren.`,
      en: `The greatest misfortune that can happen to someone is to lose patience.`,
    },
  },
  {
    label: {
      de: `Stärkere Strafen würden Verbrecher zurückschrecken lassen.`,
      en: `Severe punishment of criminals would discourage offenses.`,
    },
  },
  {
    label: {
      de: `Ich helfe gerne, wenn ich gebraucht werde.`,
      en: `I am glad to help if somebody needs me.`,
    },
  },
  {
    label: {
      de: `Eltern sind oft zu nachsichtig.`,
      en: `Parents tend to be too indulgent.`,
    },
  },
  {
    label: {
      de: `Anderen in ihrer Entwicklung zu helfen gibt mir Befriedigung.`,
      en: `Helping others in their development gives me great satisfaction.`,
    },
  },
  {
    label: {
      de: `Die Medien sollten besser kontrolliert werden.`,
      en: `Media coverage should be better controlled.`,
    },
  },
  {
    label: {
      de: `Im Management muss klar sein, wer der Boss ist.`,
      en: `In everyday management it must be clear who the boss is.`,
    },
  },
  {
    label: {
      de: `Werbung ist so erfolgreich, weil Menschen es mögen, wenn man ihnen sagt was sie kaufen sollen.`,
      en: `Advertising is successful because people like being told what to buy.`,
    },
  },
  {
    label: {
      de: `Für mich ist es wichtig mit Disziplin zu arbeiten.`,
      en: `It is important to me that discipline is maintained when working.`,
    },
  },
  {
    label: {
      de: `Patriotismus wird immer wichtiger sein als Weltbürgertum.`,
      en: `Patriotism is always going to be more important than cosmopolitanism.`,
    },
  },
  {
    label: {
      de: `Menschen sollten sich mehr mit Grundsätzen von Moral, richtig und falsch identifizieren.`,
      en: `People should identify more with principles of morality, right and wrong.`,
    },
  },
  {
    label: {
      de: `Was billig ist, ist nichts wert.`,
      en: `What's cheap is worth nothing.`,
    },
  },
  {
    label: {
      de: `Wenn ich sehe, dass jemand Schwierigkeiten mit einer Aufgabe hat, nehme ich sie ihm gerne ab.`,
      en: `When I see that someone has difficulties with his work, I gladly take it away from him.`,
    },
  },
  {
    label: {
      de: `Wir brauchen eher mehr als weniger Kontrolle in der Führung.`,
      en: `We need more rather than less control in leadership.`,
    },
  },
  {
    label: {
      de: `Traditionelle Berufe sollten in der Familie aufrechterhalten werden.`,
      en: `Professional traditions should be maintained in the family.`,
    },
  },
  {
    label: {
      de: `Eine starke Führungskraft braucht keine Mitbestimmter.`,
      en: `A strong leader does not need co-determination.`,
    },
  },
  {
    label: {
      de: `Ich komme für gewöhnlich mit allen gut aus.`,
      en: `I usually get on well with all people.`,
    },
  },
  {
    label: {
      de: `Kinder müssen ihren Eltern Respekt erweisen.`,
      en: `Children must show respect to their parents.`,
    },
  },
  {
    label: {
      de: `Ich habe Mitleid für Menschen in Schwierigkeiten.`,
      en: `I have pity for people in trouble.`,
    },
  },
  {
    label: {
      de: `Ich mache oft Überstunden beim Arbeiten.`,
      en: `I tend to work overtime at work.`,
    },
  },
  {
    label: {
      de: `Ich neige dazu, mich der Meinung der Mehrheit anzuschließen.`,
      en: `I tend to agree with the opinion of the majority.`,
    },
  },
  {
    label: {
      de: `Es ist mir wichtig, dass Zusammenarbeit ohne Konflikte erfolgt.`,
      en: `It is important to me that cooperation with others takes place without conflict.`,
    },
  },
  {
    label: {
      de: `"Undank ist der Welt Lohn" habe ich schon oft erfahren müssen.`,
      en: `"No good deed goes unpunished" is something I've had to experience.`,
    },
  },
  {
    label: {
      de: `Statt meine Zeit damit zu verschwenden anderen etwas zu erklären, mache ich es lieber selbst.`,
      en: `Instead of wasting time explaining things to others, I prefer to do it myself.`,
    },
  },
  {
    label: {
      de: `Ich bin oft erstaunt darüber, wie dumm Menschen sind.`,
      en: `I'm often amazed at how stupid people are.`,
    },
  },
  {
    label: {
      de: `Ich neige dazu anderen zu sagen, was mit ihnen nicht stimmt.`,
      en: `I tend to tell others what's wrong with them.`,
    },
  },
  {
    label: {
      de: `Viele Menschen machen Fehler indem sie Verantwortung ablehnen.`,
      en: `Many people make mistakes by rejecting responsibility.`,
    },
  },
  {
    label: {
      de: `Wenn man nicht viel erwartet, wird man nicht so leicht enttäuscht.`,
      en: `If you don't expect much, you won't be so easily disappointed.`,
    },
  },
  {
    label: {
      de: `Wenn jemand wütend auf mich ist, versuche ich ihn zu besänftigen.`,
      en: `When someone is angry with me, I try to calm them down.`,
    },
  },
  // Scale 2
  {
    label: {
      en: `It seems to me that I observe better than others.`,
      de: `Es scheint als könnte ich besser beobachten als andere.`,
    },
  },
  {
    label: {
      en: `I keep a cool head when others give up.`,
      de: "Ich kann einen kühlen Kopf bewahren, auch wenn andere aufgeben.",
    },
  },
  {
    label: {
      de: `Meine Eltern und Erzieher hatten große Freude daran, dass ich selbstständig lerne.`,
      en: `My parents and educators took great pleasure in my independent learning.`,
    },
  },
  {
    label: {
      de: `Ich sammle Informationen und plane, bevor ich handle.`,
      en: `I gather information and plan before I act.`,
    },
  },
  { label: { de: `Ich erröte selten.`, en: `I seldomly blush.` } },
  {
    label: {
      de: `Es ist einfach für mich, bei Veranstaltungen vor anderen zu sprechen.`,
      en: `It is easy for me to talk at public events.`,
    },
  },
  { label: { de: `Ich weine selten bis nie.`, en: `I seldomly/never cry.` } },
  {
    label: {
      de: `Ich bin bereit Risiken einzugehen.`,
      en: `I am prepared to take risks.`,
    },
  },
  {
    label: {
      de: `Es macht mir nichts aus, allein zu sein.`,
      en: `I don't mind being alone.`,
    },
  },
  {
    label: {
      de: `Meine Eltern schätzten es mehr den Verstand zu gebrauchen als andere.`,
      en: `My parents appreciated the use of the mind more than many other people.`,
    },
  },
  {
    label: {
      de: `Ich kann offenbleiben, auch wenn andere sich aufregen.`,
      en: `I can keep an open mind, even when others get upset.`,
    },
  },
  {
    label: {
      de: `Ich bevorzuge es Probleme zu lösen, statt zu feilschen und Kompromisse zu finden.`,
      en: `I like problem solving more than haggling and compromise.`,
    },
  },
  {
    label: {
      de: `Es ist einfach für mich meine Emotionen zu kontrollieren.`,
      en: `It is easy for me to control my emotions.`,
    },
  },
  {
    label: {
      de: `Wenn ich ein Projekt plane, binde ich Leute mit ein, die stramm arbeiten.`,
      en: `When I plan a project, I involve people who get down to it.`,
    },
  },
  {
    label: {
      de: `Ich habe strenge Vorstellungen, kann aber positiv auf Gegenargumente reagieren.`,
      en: `I have strong convictions, but react positively to reasonable counterarguments.`,
    },
  },
  {
    label: {
      de: `Währen andere zwischenmenschliche Probleme unterdrücken, versuche ich die Ursachen herauszufinden.`,
      en: `While others try to suppress interpersonal conflicts, I try to find out the causes.`,
    },
  },
  {
    label: {
      de: `Ich kann unter Stress ruhig bleiben.`,
      en: `I stay calm in stress situations.`,
    },
  },
  {
    label: {
      de: `Ich wäge die Risiken ab, bevor ich entscheide.`,
      en: `I weigh risks before I decide.`,
    },
  },
  {
    label: {
      de: `Ich versuche Meinungen und Einstellungen zu finden, die sich von meinen unterscheiden.`,
      en: `I try to find opinions and attitudes that differ from mine.`,
    },
  },
  {
    label: {
      de: `Leute, die für mich arbeiten, würden sagen, dass ich entschlossen und entscheidungsfreudig bin.`,
      en: `People who work with me would say that I am determined and decisive.`,
    },
  },
  {
    label: {
      de: `Ich kann gut mit Misserfolg umgehen.`,
      en: `I am good at handling failure.`,
    },
  },
  {
    label: {
      de: `Ich gehe mit zwischenmenschlichen Problemen um, indem ich persönliche Gespräche suche.`,
      en: `I deal with interpersonal problems with personal conversations.`,
    },
  },
  {
    label: {
      de: `Effektive Führung spornt Mitarbeiter dazu an ihr Bestes zu geben.`,
      en: `effective leadership spurs employees to give their best.`,
    },
  },
  {
    label: {
      de: `Ich glaube was andere fühlen ist wichtig.`,
      en: `I think that what others feel and think is important.`,
    },
  },
  {
    label: {
      en: `Effective leadership spurs employees to give their best.`,
      de:
        "Effektive Führungskräfte spornen Mitarbeiter dazu an ihr bestes zu geben.",
    },
  },
  {
    label: {
      de: `Ich bin interessiert an Wissenschaft und Forschung.`,
      en: `I am interested in science and research.`,
    },
  },
  {
    label: {
      de: `Ich kann eher unabhängig denken, als mich anderen anpassen.`,
      en: `I can think independently rather than adapting to others.`,
    },
  },
  {
    label: {
      de: `Ich glaube, dass Menschen fähig sind sich selbst zu führen und zu kontrollieren und sich damit entwickeln können.`,
      en: `I believe that human beings are capable of leading and controlling themselves and thus developing themselves.`,
    },
  },
  {
    label: {
      de: `Die meisten Fehler entstehen durch Missverständnisse, nicht durch Nachsicht.`,
      en: `Most mistakes are due to misunderstandings, not forbearance.`,
    },
  },
  {
    label: {
      de: `Ich kann der Welt auf entspannte und positive Art entgegentreten.`,
      en: `I can face the world in a relaxed and positive way.`,
    },
  },
  {
    label: {
      de: `Ich bin Mitglied von 3 oder mehr Organisationen.`,
      en: `I am an active member of 3 or more organisations.`,
    },
  },
  {
    label: {
      de: `Ehrlichkeit mit anderen zahlt sich in der Regel aus.`,
      en: `Honesty with others usually pays off.`,
    },
  },
  {
    label: {
      de: `Wenn ich Probleme löse agiere ich eher rational als emotional.`,
      en: `When solving problems, I act rational rather than emotional.`,
    },
  },
  {
    label: {
      de: `Ich kann äußerlich ruhig bleiben obwohl ich innerlich koche.`,
      en: `I am able to stay calm on the surface, even though I am very angry.`,
    },
  },
  {
    label: {
      de: `Ich mag es Kurse und Seminare zu besuchen.`,
      en: `I like to attend courses and seminars.`,
    },
  },
  {
    label: {
      de: `Man würde von mir behaupten, dass ich fair und objektiv bin.`,
      en: `People would say that I am fair and objective.`,
    },
  },
  {
    label: {
      de: `Ich bekomme meistens was ich will.`,
      en: `I usually get what I want.`,
    },
  },
  {
    label: {
      de: `Ich kann Dinge gut und verständlich erklären.`,
      en: `I am good at explaining things clearly.`,
    },
  },
  {
    label: {
      de: `Mein Erfolg im Leben basiert darauf, dass ich es verstehe meine Gefühle zu verstecken.`,
      en: `My success in life is based on the fact that I know how to hide my feelings.`,
    },
  },
  {
    label: {
      de: `In Diskussionen zählen meine Argumente zu den besten.`,
      en: `In a discussion, my arguments often count to the best.`,
    },
  },
  {
    label: {
      de: `Ich glaube, dass Menschen grundsätzlich gut sind.`,
      en: `I think that humans are basically good.`,
    },
  },
  {
    label: {
      de: `Es ist wichtig so perfekt wie möglich zu sein.`,
      en: `It is important to be as perfect as possible.`,
    },
  },
  {
    label: { de: `Ich lese täglich Zeitung.`, en: `I read the papers daily.` },
  },
  {
    label: {
      de: `Ich habe klare Vorstellungen darüber, wo ich in 5 Jahren professionell stehen will.`,
      en: `I have clear ideas about where I want to stand professionally in 5 years.`,
    },
  },
  // Scale 3
  {
    label: {
      de: `Ich finde, in 90% der Entscheidungen sind Gefühle wichtig.`,
      en: `I think that in 90% of all decisions, feelings are important.`,
    },
  },
  {
    label: {
      de: `Ich bemitleide mich oft selbst.`,
      en: `It seems that I often pity myself.`,
    },
  },
  {
    label: {
      de: `Wenn eine höherrangige Person eine Entscheidung trifft, werde ich helfen, auch wenn ich nicht davon überzeugt bin.`,
      en: `When a higher person makes a decision, I will help even if I am not convinced.`,
    },
  },
  {
    label: {
      de: `Ich genieße mein Leben in vollen Zügen.`,
      en: `I enjoy life to the fullest.`,
    },
  },
  { label: { de: `Ich habe oft Tagträume.`, en: `I often have daydreams. ` } },
  {
    label: {
      de: `Ich bin anfällig für Impulsiv-Käufe.`,
      en: `I am prone to impulse buying.`,
    },
  },
  {
    label: {
      de: `Es ist schwierig für mich eine Diät zu machen, mit dem Rauchen aufzuhören, …`,
      en: `It's hard for me to go on a diet, quit smoking,…`,
    },
  },
  {
    label: {
      de: `Es macht mir nichts aus der Ausführende zu sein, aber ich mag es, wenn jemand anderes führt.`,
      en: `I don't mind being the executor, but I like it when someone else takes the lead.`,
    },
  },
  {
    label: {
      de: `Ich benutze oft Wörter wie "cool", "toll", "krass", …`,
      en: `I often use words like "great", "cool", "awesome" and "terrific".`,
    },
  },
  {
    label: {
      de: `In gespannten Situationen halte ich mich zurück.`,
      en: `In a tense situation I hold myself back.`,
    },
  },
  {
    label: {
      de: `Bescheidenheit ist eine wichtige Tugend.`,
      en: `Modesty is an important virtue.`,
    },
  },
  {
    label: { de: `Ich mag es Witze zu erzählen.`, en: `I like to tell jokes.` },
  },
  {
    label: { de: `Ich habe immer neue Ideen.`, en: `I always have new ideas.` },
  },
  {
    label: {
      de: `Es macht mir nichts aus, Befehlen von anderen zu folgen.`,
      en: `I don't mind following others commands.`,
    },
  },
  {
    label: {
      de: `Es ist für mich schwieriger Befehle zu geben, als ihnen zu folgen.`,
      en: `I find it harder to give orders than to follow them.`,
    },
  },
  { label: { de: `Ich bin impulsiv.`, en: `I am impulsive.` } },
  {
    label: {
      de: `Ich würde eher zustimmen als diskutieren.`,
      en: `I would rather agree than discuss.`,
    },
  },
  {
    label: {
      de: `Die Anerkennung anderer ist mir wichtig.`,
      en: `The recognition of others is important to me.`,
    },
  },
  {
    label: {
      de: `Manchmal lache und rede ich zu laut.`,
      en: `Sometimes I laugh and talk too loudly.`,
    },
  },
  {
    label: {
      de: `Ich sage mir oft selbst "Es ist sinnlos sich hier einzubringen.".`,
      en: `I often tell myself "It's no use getting involved here".`,
    },
  },
  {
    label: {
      de: `Wenn mich jemand emotional verletzt, sage ich ihm normalerweise nichts davon.`,
      en: `If someone hurt me emotionally, I usually don't tell him about it.`,
    },
  },
  {
    label: {
      de: `Es ist für mich nicht verständlich, warum so viele Menschen das Leben so ernst nehmen.`,
      en: `It is hard for me to understand how so many people take life so seriously.`,
    },
  },
  {
    label: {
      de: `Ich rede oft nicht über meine Ideen, weil sie mir nicht so wichtig erscheinen.`,
      en: `I often don't talk about my ideas, because they don't seem too important to me.`,
    },
  },
  {
    label: {
      de: `Meine Eltern respektieren es, wenn ich Gefühle wie Freude, Trauer oder Wut ausdrücke.`,
      en: `My parents respect when I show feelings like joy, sadness, anger etc.`,
    },
  },
  {
    label: {
      de: `Ich fühle mich so als ob ich mich oft nicht durchsetzen kann.`,
      en: `I feel like I don't get my own way as often as I'd like to.`,
    },
  },
  {
    label: {
      de: `Ich ziehe Jobs mit weniger Verantwortung vor.`,
      en: `I prefer jobs with less responsibility.`,
    },
  },
  {
    label: {
      de: `Es kann sein, dass meine Eltern mir eher Angst vor der Welt gemacht haben, statt mir die freudige Seite zu zeigen.`,
      en: `It could be that my parents tended to make me frightened of the world, instead of showing me the joyful side.`,
    },
  },
  {
    label: {
      de: `Ich habe mehr Interessen als die meisten Leute, die ich kenne.`,
      en: `I have more interests than most people I know.`,
    },
  },
  {
    label: {
      de: `Es passiert oft, dass ich den Kürzeren ziehe.`,
      en: `It often happens that I come off second best.`,
    },
  },
  {
    label: {
      de: `An einem Freitagnachmittag sitzt du mit Freunden zusammen und einer bekommt auf einmal die Idee, spontan für 2 Tage nach Paris zu fliegen. Würdest du mitmachen?.`,
      en: `On a Friday afternoon you sit together with friends and one of them suddenly gets the idea to spontaneously fly to Paris for two days. Would you join?.`,
    },
  },
  {
    label: {
      de: `Meine Lösungen sind eher fantasievoll als logisch.`,
      en: `My solutions are imaginative rather than logical.`,
    },
  },
  {
    label: {
      de: `Ich kann vor anderen weinen, ohne mich zu schämen.`,
      en: `I can cry in front of others without being ashamed.`,
    },
  },
  {
    label: {
      de: `Ich habe gelernt eine freudige Einstellung gegenüber meinem Körper und meiner Intimität zu entwickeln.`,
      en: `I have learned to adopt a joyful attitude towards my body and my intimacy.`,
    },
  },
  {
    label: {
      de: `Man muss sich wichtigen Persönlichkeiten unterordnen.`,
      en: `One must submit to important personalities.`,
    },
  },
  {
    label: {
      de: `Es gibt Zeiten, in denen ich mich selbst mit außergewöhnlichen Freuden belohne.`,
      en: `There are times when I like to treat myself to extraordinary joys.`,
    },
  },
  {
    label: {
      de: `Ich fühle mich unwohl in ungewohnten Situationen.`,
      en: `I feel uncomfortable in unfamiliar situations.`,
    },
  },
  {
    label: {
      de: `Ich finde mich oft in Mitten eines Problems und frage mich, wie ich da hineingeraten bin.`,
      en: `I often find myself in a problem and ask myself how I got there.`,
    },
  },
  {
    label: {
      de: `Ich fühle mich oft hilflos.`,
      en: `I feel helpless in many situations.`,
    },
  },
  {
    label: {
      de: `Wenn ich etwas sage, kann es gut sein, dass ich ins Fettnäpfchen trete.`,
      en: `If I say something, I might just put my foot in it.`,
    },
  },
];
