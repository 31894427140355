import { TranslatedText } from "../types";

export interface QuestionDatum {
  label: TranslatedText;
}

export const description: TranslatedText = {
  en:
    "Read the sentences and cross where applicable. Answer honestly, even if you don't like the answer and try to leave as few neutral answers as possible.",
  de:
    "Kreuze Zutreffendes an und antworte ehrlich, auch wenn du die Antwort vielleicht nicht magst. Versuche dabei so wenig neutrale Antworten wie möglich zu geben.",
};

export const data: QuestionDatum[] = [
  {
    label: {
      en: "You enjoy vibrant social events with lots of people.",
      de: "Du magst Veranstaltungen mit vielen Leuten.",
    },
  },
  {
    label: {
      en: "You often spend time exploring unrealistic, yet intriguing ideas.",
      de:
        "Du verbringst oft deine Zeit damit unrealistische und faszinierende Ideen auszudenken.",
    },
  },
  {
    label: {
      en:
        "Your travel plans are more likely to look like a rough list of ideas rather than a detailed itinerary.",
      de:
        "Deine Reisepläne sehen eher aus wie eine ungefähre Liste von Ideen, als ein detaillierter Plan.",
    },
  },
  {
    label: {
      en:
        "You often think about what you should have said in a conversation long after it has taken place.",
      de:
        "Du denkst oft darüber nach, was du in einer Konversation gesagt haben könntest, lange nachdem diese schon vorbei ist.",
    },
  },
  {
    label: {
      en:
        "If a friend is sad about something, your first instinct is to support them emotionally, not try to solve their problem.",
      de:
        "Wenn ein Freund traurig über etwas ist, ist es dein erster Instinkt ihn emotional zu unterstützen, nicht seine Probleme zu lösen.",
    },
  },
  {
    label: {
      en: "People can rarely upset you.",
      de: "Man kann dich nicht leicht aufregen.",
    },
  },
  {
    label: {
      en:
        "You often rely on other people to be the ones to start a conversation and keep it going.",
      de:
        "Du verlässt dich eher auf andere Leute ein Gespräch anzufangen und aufrecht zu erhalten.",
    },
  },
  {
    label: {
      en:
        "If you have to temporarily put your plans on hold, you make sure it is your top priority to get back on track as soon as possible.",
      de:
        "Wenn du deine Pläne zeitweise stoppen musst, hat es für dich Priorität so schnell wie möglich fortfahren zu können.",
    },
  },
  {
    label: {
      en: "You rarely worry if you made a good impression on someone you met.",
      de:
        "Du machst dir selten Gedanken darüber, ob du einen guten Eindruck auf jemanden gemacht hast.",
    },
  },
  {
    label: {
      en:
        "It would be a challenge for you to spend the whole weekend all by yourself without feeling bored.",
      de:
        "Es wäre eine große Herausforderung für dich, ein ganzes Wochenende allein zu verbringen, ohne dass dir langweilig wird.",
    },
  },
  {
    label: {
      en: "You are more of a detail-orientated than a big-picture person.",
      de:
        "Du bist eher eine Detail-orientierte Person, als jemand der das große Ganze betrachtet.",
    },
  },
  {
    label: {
      en: "You are very affectionate with people you care about.",
      de: "Du bist Leuten, die dir wichtig sind, sehr zugetan.",
    },
  },
  {
    label: {
      en: "You are still bothered by the mistakes you made a long time ago.",
      de:
        "Du machst dir immer noch Gedanken über Fehler, die du vor längerer Zeit gemacht hast.",
    },
  },
  {
    label: {
      en: "You have a careful and methodical approach to life.",
      de:
        "Du hast eine vorsichtige und methodische Herangehensweise zum Leben.",
    },
  },
  {
    label: {
      en:
        "At parties and similar events you can mostly be found further away from the action.",
      de: "Bei Partys kann man dich eher weiter weg vom Getümmel finden.",
    },
  },
  {
    label: {
      en:
        "you often find it difficult to relate to people who let their emotions guide them.",
      de:
        "Du findest es oft schwer dich in Menschen hineinzuversetzen, die emotionsgetrieben sind.",
    },
  },
  {
    label: {
      en:
        "When looking for a movie to watch, you can spend ages browsing through the catalog.",
      de:
        "Wenn du einen Film sehen willst, könntest du Jahre damit verbringen die Film-Liste durchzuschauen.",
    },
  },
  {
    label: {
      en: "You can stay calm under a lot of stress.",
      de: "Du kannst unter Stress gut ruhig bleiben.",
    },
  },
  {
    label: {
      en:
        "When in a group of people you don't know, you have no problem jumping right into their conversation.",
      de:
        "Wenn du in einer Gruppe bist und niemanden kennst, ist es für dich kein Problem direkt in ein Gespräch einzusteigen.",
    },
  },
  {
    label: {
      en: "When you sleep, your dreams tend to be bizarre fantastical.",
      de: "Wenn du schläfst sind deine Träume eher bizarr und fantastisch.",
    },
  },
  {
    label: {
      en:
        "In your opinion, it is sometimes okay to step on others to get ahead in life.",
      de:
        "Deiner Meinung nach ist es okay manchmal andere zu benutzen, um im Leben weiterzukommen.",
    },
  },
  {
    label: {
      en:
        "You are dedicated and focused on your goals, only rarely getting side-tracked.",
      de: "Du hast immer dein Ziel im Fokus und lässt dich selten ablenken.",
    },
  },
  {
    label: {
      en:
        "If you make a mistake, you tend to start doubting yourself, your abilities, or your knowledge.",
      de:
        "Wenn du einen Fehler machst, neigst du dazu an dir, deinen Fähigkeiten oder deinem Wissen zu zweifeln.",
    },
  },
  {
    label: {
      en:
        "When at a social event, you rarely try to introduce yourself to new people and mostly talk to the ones you already know.",
      de:
        "Bei Veranstaltungen stellst du dich eher selten neuen Leuten vor und redest eher mit denen, die du schon kennst.",
    },
  },
  {
    label: {
      en:
        "You usually lose interest in a discussion when it gets philosophical.",
      de:
        "Du verlierst schnell Interesse an einer Diskussion, wenn sie beginnt philosophisch zu werden.",
    },
  },
  {
    label: {
      en: "You would never let yourself cry in front of others.",
      de: "Du würdest niemals vor anderen weinen.",
    },
  },
  {
    label: {
      en:
        "You feel more drawn to places with a bustling and busy attitude than to more quiet and intimate ones.",
      de:
        "Du fühlst dich eher zu lebhaften Orten hingezogen, als zu ruhigeren.",
    },
  },
  {
    label: {
      en:
        "You like discussing views and theories on what the world would look like in the future.",
      de:
        "Du magst es, über verschiedene Ansichten und Theorien zu reden, wie die Welt wohl in der Zukunft aussehen könnte.",
    },
  },
  {
    label: {
      en:
        "When it comes to making life-changing choices, you mostly listen to your heart rather than your head.",
      de:
        "Wenn es um große Entscheidungen geht, hörst du eher auf dein Herz, als auf deinen Kopf.",
    },
  },
  {
    label: {
      en:
        "You can't imagine yourself dedicating your life to the study of something you can't see, touch or experience.",
      de:
        "Du könntest es dir nicht vorstellen dich für eine Lehre über etwas, dass du nicht sehen, anfassen oder erleben kannst, zu engagieren.",
    },
  },
  {
    label: {
      en: "You usually prefer to get your revenge, rather than to forgive.",
      de: "Du ziehst es eher vor dich zu rächen, als zu vergeben.",
    },
  },
  {
    label: {
      en: "You often make decisions on a whim.",
      de: "Du triffst Entscheidungen gerne spontan.",
    },
  },
  {
    label: {
      en:
        "The time you spend by yourself often ends up being more interesting and satisfying than the time you spend with others.",
      de:
        "Die Zeit, die du allein verbringst, ist im Endeffekt oft interessanter und befriedigender für dich als die Zeit, die du mit anderen verbringst.",
    },
  },
  {
    label: {
      en:
        "You put special effort into interpreting the real meaning or the message of a song or a movie.",
      de:
        "Du interpretierst gerne die Bedeutungen und Botschaften von Liedern und Filmen.",
    },
  },
  {
    label: {
      en: "You always know exactly what you want.",
      de: "Du weißt immer genau was du willst.",
    },
  },
  {
    label: {
      en:
        "You rarely think back on the choices you made and wonder what you could have done differently.",
      de:
        "Du denkst selten über vergangene Entscheidungen nach und wunderst dich, was anders hätte sein können.",
    },
  },
  {
    label: {
      en:
        "When in a public place, you usually stick to quieter and less crowded areas.",
      de:
        "Wenn du in der Öffentlichkeit bist, findet man dich eher in ruhigeren Bereichen mit weniger Leuten.",
    },
  },
  {
    label: {
      en:
        "You tend to focus on present realities rather than future possibilities.",
      de: "Dir ist das hier und jetzt wichtiger als die Zukunft.",
    },
  },
  {
    label: {
      en: "You often have a hard time understanding other people's feelings.",
      de: "Dir fällt es oft schwer die Gefühle anderer zu verstehen.",
    },
  },
  {
    label: {
      en:
        "When starting to work on a project, you prefer to make as many decisions upfront as possible.",
      de:
        "Wenn du an einem Projekt mitarbeitest, ziehst du es vor so viele Entscheidungen wie möglich mitzumachen.",
    },
  },
  {
    label: {
      en:
        "When you know someone thinks highly of you , you also wonder how long it will be until they become disappointed in you.",
      de:
        "Wenn jemand gut über dich denkt, wunderst du dich auch wie lange es dauert, bis er enttäuscht von dir sein wird.",
    },
  },
  {
    label: {
      en:
        "You feel comfortable just walking up to someone you find interesting and striking up a conversation.",
      de:
        "Es macht dir nichts aus zu jemandem, den du interessant findest, hinzugehen und ein Gespräch anzufangen.",
    },
  },
  {
    label: {
      en:
        "You often drift away into daydreaming about various ideas and scenarios.",
      de: "Du hast oft Tagträume.",
    },
  },
  {
    label: {
      en: "You look after yourself first, others come second.",
      de: "Du stehst für dich an erster Stelle, alle anderen kommen danach.",
    },
  },
  {
    label: {
      en:
        "Even when you planned a particular daily routine, you usually end up doing whatever you feel like.",
      de:
        "Auch wenn du schon genaue Pläne für einen Tag hast, passiert es normalerweise, dass du einfach tust wonach dir gerade ist.",
    },
  },
  {
    label: {
      en: "Your mood can change quickly.",
      de: "Deine Stimmung kann sich schnell ändern.",
    },
  },
  {
    label: {
      en:
        "You often contemplate the reasons for human existence and the meaning of life.",
      de:
        "Du denkst oft über menschliche Existenz und den Sinn des Lebens nach.",
    },
  },
  {
    label: {
      en: "You often talk about your own feelings and emotions.",
      de: "Du redest oft über deine eigenen Gefühle und Emotionen.",
    },
  },
  {
    label: {
      en:
        "You have got detailed education- or career development-plans stretching several years into the future.",
      de:
        "Du hast einen detaillierten Plan für deine Ausbildung und Karriere, der sich weit in die Zukunft streckt.",
    },
  },
  {
    label: {
      en: "You rarely dwell on your regrets.",
      de: "Du bereust selten etwas.",
    },
  },
  {
    label: {
      en: "You see yourself more as a realist than a visionary.",
      de: "Du siehst dich selbst eher als Realist, als als Visionär.",
    },
  },
  {
    label: {
      en:
        "Spending time in a dynamic atmosphere with lots of people around quickly makes you feel drained and in need of a getaway.",
      de:
        "Wenn du Zeit in einem sehr aktiven Umfeld mit vielen Leuten verbringst, fühlst du dich schnell erschöpft und willst gehen.",
    },
  },
  {
    label: {
      en:
        "You find it easy to empathise with a person who has gone through something you never have.",
      de:
        "Es ist einfach für dich, sich in jemanden hineinzuversetzen, der etwas erlebt hat was du nie erlebt hast.",
    },
  },
  {
    label: {
      en:
        "Your personal work-style is closer to spontaneous bursts of energy than to organised and consistent efforts.",
      de: "Dein Arbeitsstil ist eher spontan als organisiert.",
    },
  },
  {
    label: {
      en: "Your emotions control you more than you control them.",
      de: "Deine Emotionen steuern dich mehr als du sie.",
    },
  },
  {
    label: {
      en:
        "After a long and exhausting week, a fun party is just what you need.",
      de:
        "Nach einer langen anstrengenden Woche ist eine Party genau das was du brauchst.",
    },
  },
  {
    label: {
      en:
        "You frequently find yourself wondering how technological advancement could change everyday life.",
      de:
        "Du fragst dich immer wieder, wie Technologie den Alltag verändern könnte.",
    },
  },
  {
    label: {
      en:
        "You always consider how your actions might affect other people before doing something.",
      de:
        "Du denkst immer daran, dass deine Aktionen andere Leute beeinflussen könnten, bevor du etwas tust.",
    },
  },
  {
    label: {
      en:
        "You still honour the commitments you made, even if you have a change of heart.",
      de:
        "Du hältst immer noch deine Verpflichtungen ein, auch wenn du dazwischen deine Meinung änderst.",
    },
  },
  {
    label: {
      en: "You rarely feel insecure.",
      de: "Du fühlst dich selten unsicher.",
    },
  },
];
