import React, { useCallback, memo } from "react";
import { useStyles } from "./NavigationButtons.styles";
import { Button } from "@material-ui/core";

import { useHotkeys } from "react-hotkeys-hook";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckIcon from "@material-ui/icons/Check";
import { Translation, useTranslation } from "../../../hooks/translation";

const translations: Translation<Record<
  "previous" | "next" | "finish_part",
  string
>> = {
  en: {
    previous: "Previous",
    next: "Next",
    finish_part: "Finish part",
  },
  de: {
    previous: "Zurück",
    next: "Weiter",
    finish_part: "Teil beenden",
  },
};

interface Props {
  previous: () => void;
  next: () => void;
  hasPrevious: boolean;
  hasNext: boolean;
  handlePartFinish: () => void;
  isFinished: boolean;
}

function NavigationButtonsComponent({
  previous,
  next,
  hasNext,
  hasPrevious,
  handlePartFinish,
  isFinished,
}: Props) {
  const classes = useStyles();

  const translated = useTranslation(translations);

  const nextCallback = useCallback(
    () => (hasNext ? next() : isFinished ? handlePartFinish() : undefined),
    [hasNext, next, isFinished, handlePartFinish],
  );

  useHotkeys("right", nextCallback, [nextCallback]);
  useHotkeys("enter", nextCallback, [nextCallback]);
  useHotkeys("space", nextCallback, [nextCallback]);
  useHotkeys("left", () => hasPrevious && previous(), [hasPrevious, previous]);

  return (
    <div className={classes.root}>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={previous}
        disabled={!hasPrevious}
        startIcon={<ArrowBackIcon />}
      >
        {translated.previous}
      </Button>
      {isFinished && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handlePartFinish}
          endIcon={<CheckIcon />}
        >
          {translated.finish_part}
        </Button>
      )}
      <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={next}
        disabled={!hasNext}
        endIcon={<ArrowForwardIcon />}
      >
        {translated.next}
      </Button>
    </div>
  );
}

export const NavigationButtons = memo(NavigationButtonsComponent);
