import React, { useState, useEffect, memo } from "react";
import { QuestionDatum } from "./data";
import { ResultValue } from "./types";

import Row from "./Row";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from "@material-ui/core";
import { Translation, useTranslation } from "../../../hooks/translation";

const translations: Translation<Record<
  "answer" | "leastApplicable" | "mostApplicable",
  string
>> = {
  de: {
    answer: "Antwort",
    leastApplicable: "Am wenigsten zutreffend",
    mostApplicable: "Am meisten zutreffend",
  },
  en: {
    answer: "Answer",
    leastApplicable: "Least applicable",
    mostApplicable: "Most applicable",
  },
};

interface Props {
  question: QuestionDatum;
  onAnswer: (data: ResultValue) => void;
  result?: ResultValue;
}

function Question({ question, onAnswer, result }: Props) {
  const [mostApplicable, setMostApplicable] = useState(result?.mostApplicable);
  const [leastApplicable, setLeastApplicable] = useState(
    result?.leastApplicable,
  );

  const i18n = useTranslation(translations);

  useEffect(() => {
    setMostApplicable(result?.mostApplicable);
    setLeastApplicable(result?.leastApplicable);
  }, [question, result]);

  const setMostApplicableAndSetResult = (index: number) => {
    setMostApplicable(index);
    if (leastApplicable !== undefined) {
      onAnswer({ leastApplicable, mostApplicable: index });
    }
  };

  const setLeastApplicableAndSetResult = (index: number) => {
    setLeastApplicable(index);
    if (mostApplicable !== undefined) {
      onAnswer({ leastApplicable: index, mostApplicable });
    }
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body1">{i18n.answer}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1">{i18n.mostApplicable}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body1">{i18n.leastApplicable}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {question.answers.map((question, index) => (
          <Row
            question={question}
            index={index}
            setMostApplicable={setMostApplicableAndSetResult}
            setLeastApplicable={setLeastApplicableAndSetResult}
            mostApplicable={mostApplicable}
            leastApplicable={leastApplicable}
            key={index}
          />
        ))}
      </TableBody>
    </Table>
  );
}

export default memo(Question);
