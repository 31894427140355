import React, { useCallback, memo } from "react";
import Part1 from "./Part1";
import Part2 from "./Part2";
import Part3 from "./Part3";
import { Finish } from "./Finish";
import Introduction from "./Introduction";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useStyles } from "./index.styles";
import { Pages } from "./Pages";
import General from "./General";
import { AnimationWrapper } from "../../components/AnimationWrapper";

import { Transition } from "react-spring/renderprops";

import { useLocation } from "react-router";

const PAGE_PREFIX = "/test/";

export const getPageRoute = (page: Pages) => `${PAGE_PREFIX}${page}`;

interface Props {}

function Test(props: Props) {
  const { push } = useHistory();

  const handleGeneralFinish = useCallback(() => {
    push(getPageRoute(Pages.Part1));
  }, [push]);

  const handlePart1Finish = useCallback(() => {
    push(getPageRoute(Pages.Part2));
  }, [push]);
  const handlePart2Finish = useCallback(() => {
    push(getPageRoute(Pages.Part3));
  }, [push]);
  const handlePart3Finish = useCallback(() => {
    push(getPageRoute(Pages.Finish));
  }, [push]);

  const classes = useStyles();

  const location = useLocation();

  return (
    <div className={classes.root}>
      <Transition
        items={location}
        keys={location.pathname}
        from={{
          opacity: 0,
          transform: "translateX(100%)",
          position: "absolute",
        }}
        enter={{
          opacity: 1,
          transform: "translateX(0%)",
        }}
        leave={{
          opacity: 0,
          transform: "translateX(-100%)",
        }}
      >
        {(loc: any, state: any) => (style: any) => (
          <Switch location={state === "update" ? location : loc}>
            <Route path={getPageRoute(Pages.Introduction)}>
              <AnimationWrapper style={style}>
                <div className={classes.card}>
                  <div className={classes.content}>
                    <Introduction />
                  </div>
                </div>
              </AnimationWrapper>
            </Route>
            <Route path={getPageRoute(Pages.General)}>
              <AnimationWrapper style={style}>
                <div className={classes.card}>
                  <div className={classes.content}>
                    <General onGeneralFinish={handleGeneralFinish} />
                  </div>
                </div>
              </AnimationWrapper>
            </Route>
            <Route path={getPageRoute(Pages.Part1)}>
              <AnimationWrapper style={style}>
                <div className={classes.card}>
                  <div className={classes.content}>
                    <Part1 onPartFinish={handlePart1Finish} />
                  </div>
                </div>
              </AnimationWrapper>
            </Route>
            <Route path={getPageRoute(Pages.Part2)}>
              <AnimationWrapper style={style}>
                <div className={classes.card}>
                  <div className={classes.content}>
                    <Part2 onPartFinish={handlePart2Finish} />
                  </div>
                </div>
              </AnimationWrapper>
            </Route>
            <Route path={getPageRoute(Pages.Part3)}>
              <AnimationWrapper style={style}>
                <div className={classes.card}>
                  <div className={classes.content}>
                    <Part3 onPartFinish={handlePart3Finish} />
                  </div>
                </div>
              </AnimationWrapper>
            </Route>
            <Route path={getPageRoute(Pages.Finish)}>
              <AnimationWrapper style={style}>
                <div className={classes.card}>
                  <div className={classes.content}>
                    <Finish />
                  </div>
                </div>
              </AnimationWrapper>
            </Route>
            <Redirect to={getPageRoute(Pages.Introduction)} />
          </Switch>
        )}
      </Transition>
    </div>
  );
}

export default memo(Test);
