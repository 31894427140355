import useLocalStorage from "@rehooks/local-storage";

const ALLOWED_LANGUAGES = ["en", "de"] as const;

export type LANGUAGES = typeof ALLOWED_LANGUAGES[number];

const DEFAULT_LANGUAGE = ALLOWED_LANGUAGES[0];

export type Translation<Data> = Record<LANGUAGES, Data>;

export const useLanguage = () => {
  const [language, setLanguage] = useLocalStorage<LANGUAGES>("language");
  return { language: language ?? DEFAULT_LANGUAGE, setLanguage };
};

export const useTranslation = <Data extends any>(
  translation: Translation<Data>,
) => {
  const [language] = useLocalStorage<LANGUAGES>("language");

  const data = translation[language ?? DEFAULT_LANGUAGE];

  return data;
};
