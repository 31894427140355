import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  root: {},
  buttons: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
  buttonText: {
    [theme.breakpoints.up("md")]: {
      "&:first-letter": {
        fontSize: "125%",
      },
    },
  },
  selected: {
    background: theme.palette.success.dark,
  },
}));
