import { makeStyles } from "@material-ui/core";
import { primary } from "../../theme";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    minHeight: "100vh",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    justifyContent: "space-between",
  },
  nav: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: theme.spacing(),
      marginBottom: 0,
    },
    "& > *:last-child": {
      marginBottom: theme.spacing(),
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      "& > *": {
        margin: 0,
        marginBottom: "0 !important",
        marginRight: theme.spacing(),
      },
    },
  },
  button: {
    transition: "background-color linear 0.2s, color linear 0.2s",
  },
  disabledButton: {
    backgroundColor: primary["A100"] + " !important",
    color: "#fff",
  },
}));
