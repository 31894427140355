import { Part1ResultDatum } from "../Part1/types";
import { Part2ResultDatum } from "../Part2/types";
import { Part3ResultDatum } from "../Part3/types";
import { GeneralResult } from "../General/types";

export interface Data {
  general: GeneralResult;
  part1: Part1ResultDatum[];
  part2: Part2ResultDatum[];
  part3: Part3ResultDatum[];
}

const prepareGeneral = (data: GeneralResult) => {
  const resultData = [];
  resultData.push(["General"]);
  resultData.push(["Name", data.name]);
  resultData.push(["Age", data.age]);
  resultData.push(["Gender", data.gender]);

  return resultData;
};

const preparePart1 = (data: Part1ResultDatum[]) => {
  const resultData = [];
  resultData.push(["Part 1"]);
  resultData.push([
    "Question Nr",
    "Result (1 = strongly disagree - 7 = strongly agree)",
  ]);
  data.forEach((result, index) => {
    resultData.push([index, result]);
  });
  return resultData;
};

const preparePart2 = (data: Part2ResultDatum[]) => {
  const resultData = [];
  resultData.push(["Part 2"]);
  resultData.push(["Question Nr", "Most applicable", "Least applicable"]);
  data.forEach(({ mostApplicable, leastApplicable }, index) => {
    resultData.push([index, mostApplicable, leastApplicable]);
  });
  return resultData;
};

const preparePart3 = (data: Part3ResultDatum[]) => {
  const resultData = [];
  resultData.push(["Part 3"]);
  resultData.push(["Question Nr", "Applicable (yes / no)"]);
  data.forEach(({ applicable }, index) => {
    resultData.push([index, applicable ? 1 : 0]);
  });
  return resultData;
};

export const prepareData = ({ general, part1, part2, part3 }: Data) => {
  const resultGeneral = prepareGeneral(general);
  const resultPart1 = preparePart1(part1);
  const resultPart2 = preparePart2(part2);
  const resultPart3 = preparePart3(part3);

  return [...resultGeneral, ...resultPart1, ...resultPart2, ...resultPart3];
};
