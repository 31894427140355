import React from "react";
import { Link } from "react-router-dom";
import { getPageRoute } from "../../pages/Test";
import { Button } from "@material-ui/core";

import { useTranslation } from "../../hooks/translation";
import { Pages, pageTranslations } from "../../pages/Test/Pages";

interface Props {
  page: Pages;
  isLocked: boolean;
  isDesktop: boolean;
  highlighted?: boolean;
}

export const CustomLink = ({
  page,
  isLocked,
  isDesktop,
  highlighted,
}: Props) => {
  const translated = useTranslation(pageTranslations);

  if (isLocked) {
    return (
      <span>
        <Button disabled variant="outlined" fullWidth={!isDesktop}>
          {translated[page]}
        </Button>
      </span>
    );
  }
  return (
    <Link to={getPageRoute(page)}>
      <Button
        variant="outlined"
        fullWidth={!isDesktop}
        color={highlighted ? "primary" : undefined}
      >
        {translated[page]}
      </Button>
    </Link>
  );
};
