import React, { memo } from "react";
import CancelIcon from "@material-ui/icons/Block";
import HelpIcon from "@material-ui/icons/HelpOutline";
import { useStyles } from "./CancelHelpIcon.styles";

const CancelHelpIconComponent = () => {
  const classes = useStyles();

  return (
    <span
      className={classes.root}
      style={{ position: "relative", lineHeight: 1 }}
    >
      <HelpIcon />
      <CancelIcon className={classes.overlayIcon} />
    </span>
  );
};

export const CancelHelpIcon = memo(CancelHelpIconComponent);
