import FavoriteIcon from "@material-ui/icons/Favorite";
import { withStyles } from "@material-ui/core";

const HeartIcon = withStyles({
  "@keyframes pump": {
    "0%": { transform: "scale(1)" },
    "50%": { transform: "scale(1.1)" },
    "100%": { transform: "scale(1)" },
  },
  root: {
    animationName: "$pump",
    animationDuration: "1s",
    animationIterationCount: "infinite",
  },
})(FavoriteIcon);

export const Heart = HeartIcon;
