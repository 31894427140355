import { useState, useCallback } from "react";

import { useLocalStorage } from "@rehooks/local-storage";

export const useData = <Datum>(data: Datum[], initialIndex: number = 0) => {
  const [currentIndex, setCurrentIndex] = useState(
    data.length > initialIndex ? initialIndex : data.length - 1,
  );

  const totalCount = data.length;

  const hasNext = totalCount > currentIndex + 1;
  const hasPrevious = currentIndex !== 0;

  const next = useCallback(
    () => hasNext && setCurrentIndex((value) => value + 1),
    [hasNext],
  );
  const previous = useCallback(
    () => hasPrevious && setCurrentIndex((value) => value - 1),
    [hasPrevious],
  );

  return {
    current: data[currentIndex],
    next,
    previous,
    hasNext,
    hasPrevious,
    currentIndex,
    totalCount,
    all: data,
  };
};

export const useResult = <Result, Datum>(data: Datum[], key: string) => {
  const [results, setResults] = useLocalStorage<Result[]>(key, []);

  const {
    totalCount,
    currentIndex,
    hasNext: hasNextData,
    next: nextData,
    ...restData
  } = useData(data, results?.length);

  const addResult = useCallback(
    (result: Result, index: number = currentIndex) => {
      const newValues = [...(results || [])];
      newValues[index] = result;

      setResults(newValues);
    },
    [currentIndex, results, setResults],
  );

  const hasResult = results?.[currentIndex] !== undefined;

  const hasNext = hasNextData && hasResult;
  const next = useCallback(() => hasNextData && nextData(), [
    hasNextData,
    nextData,
  ]);

  const currentResult: Result | undefined = results?.[currentIndex];

  const isFinished = totalCount === results?.length && hasResult;

  return {
    hasResult,
    isFinished,
    addResult,
    currentIndex,
    currentResult,
    hasNext,
    next,
    allResults: results || [],
    totalCount,
    ...restData,
  };
};
