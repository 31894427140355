import React, { memo } from "react";
import { Link } from "react-router-dom";
import { getPageRoute } from "..";
import { Pages } from "../Pages";
import { Button } from "@material-ui/core";
import { useStyles } from "./index.styles";
import { Translation, useTranslation } from "../../../hooks/translation";

const translations: Translation<Record<
  "title" | "stored_locally" | "download" | "startButton" | "make_a_pause",
  string
>> = {
  en: {
    title: "Welcome and thanks for taking this test!",
    make_a_pause: "Don't forget to make pauses between the different parts.",

    stored_locally:
      "Your test results are only stored on your device and are NOT processed automatically.",
    download:
      "You can download your test data as soon as you've finished. Please send us the downloaded file to get it evaluated.",
    startButton: "Begin test",
  },

  de: {
    title: "Hallo! Danke, dass du bei diesem Test mitmachst!",

    stored_locally:
      "Deine Daten werden nur auf deinem Gerät gespeichert. Sie werden NICHT automatisch an uns gesendet.",
    download:
      "Sobald du den Test beendet hast, kannst du deine Daten herunterladen. Bitte schicke uns die heruntergeladene Datei um eine Auswertung zu bekommen.",
    make_a_pause:
      "Vergiss nicht Pausen während den einzelnen Teilen zu machen!",
    startButton: "Test beginnen",
  },
};

function Introduction() {
  const classes = useStyles();

  const i18n = useTranslation(translations);

  return (
    <>
      <h2>{i18n.title}</h2>

      <p>{i18n.stored_locally}</p>
      <p>{i18n.download}</p>
      <p>{i18n.make_a_pause}</p>

      <Link to={getPageRoute(Pages.General)}>
        <Button className={classes.button} variant="contained" color="primary">
          {i18n.startButton}
        </Button>
      </Link>
    </>
  );
}

export default memo(Introduction);
