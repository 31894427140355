import React, { memo } from "react";
import { TranslatedText } from "../types";
import { ResultValue } from "./types";
import { useTranslation } from "../../../hooks/translation";
import { useStyles } from "./AnswerButton.styles";

import { useHotkeys } from "react-hotkeys-hook";

import clsx from "classnames";

export interface ButtonConfig {
  label: TranslatedText;
  value: ResultValue;
}

interface Props {
  config: ButtonConfig;
  onAnswer: (data: ResultValue) => void;
  isSelected: boolean;
}

function AnswerButton({ config, onAnswer, isSelected }: Props) {
  const label = useTranslation(config.label);
  const handleAnswer = () => onAnswer(config.value);
  const classes = useStyles();
  useHotkeys(config.value.toString(), handleAnswer, [handleAnswer]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.odd]: config.value % 2 === 0,
        [classes.selected]: isSelected,
      })}
      onClick={handleAnswer}
    >
      <div className={classes.value}>
        <span>{config.value}</span>
      </div>
      <div className={classes.label}>{label}</div>
    </div>
  );
}

export default memo(AnswerButton);
