import { useLocalStorage } from "@rehooks/local-storage";

export const useProgress = () => {
  const [general] = useLocalStorage("general");
  const [part1] = useLocalStorage("part1");
  const [part2] = useLocalStorage("part2");
  const [part3] = useLocalStorage("part3");

  return [!!general, !!part1, !!part2, !!part3];
};
