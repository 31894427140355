import React, { memo } from "react";
import { QuestionDatum } from "./data";
import { ResultValue } from "./types";
import { useTranslation, Translation } from "../../../hooks/translation";
import { Button, Typography } from "@material-ui/core";
import { useStyles } from "./Question.styles";

import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";

import { useHotkeys } from "react-hotkeys-hook";

import clsx from "classnames";

const translations: Translation<Record<"yes" | "no", string>> = {
  de: {
    yes: "Ja",
    no: "Nein",
  },
  en: {
    yes: "Yes",
    no: "No",
  },
};

interface Props {
  question: QuestionDatum;
  onAnswer: (data: ResultValue) => void;
  result?: ResultValue;
}

function Question({ question, onAnswer, result }: Props) {
  const label = useTranslation(question.label);
  const i18n = useTranslation(translations);
  const classes = useStyles();

  const yes = () => onAnswer({ applicable: true });
  const no = () => onAnswer({ applicable: false });

  useHotkeys("y", yes, [yes]);
  useHotkeys("j", yes, [yes]);

  useHotkeys("n", no, [no]);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {label}
      </Typography>
      <div className={classes.buttons}>
        <Button
          color="primary"
          variant="contained"
          className={clsx({ [classes.selected]: result?.applicable })}
          endIcon={<CheckIcon />}
          onClick={yes}
        >
          <Typography className={classes.buttonText}>{i18n.yes}</Typography>
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={clsx({ [classes.selected]: result?.applicable === false })}
          endIcon={<CancelIcon />}
          onClick={no}
        >
          <Typography className={classes.buttonText}>{i18n.no}</Typography>
        </Button>
      </div>
    </>
  );
}

export default memo(Question);
