import { TranslatedText } from "../types";

export interface SingleQuestion {
  label: TranslatedText;
}

export interface QuestionDatum {
  answers: SingleQuestion[];
}

export const description: TranslatedText = {
  de:
    "Suche einen am meisten zutreffenden und einen am wenigsten zutreffenden Satz für alle 28 Fälle aus.",
  en:
    "Choose one most applicable and one least applicable short sentence for all 28 cases.",
};

export const data: QuestionDatum[] = [
  {
    answers: [
      {
        label: {
          en: "I am devoted to others.",
          de: "Ich bin anderen zugetan.",
        },
      },
      {
        label: {
          en: "I draw attention to myself.",
          de: "Ich ziehe die Aufmerksamkeit auf mich.",
        },
      },
      {
        label: {
          en: "I rarely make mistakes.",
          de: "Ich mache selten Fehler.",
        },
      },
      { label: { en: "I want to win.", de: "Ich möchte gewinnen." } },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I finish what I started.",
          de: "Ich beende, was ich angefangen habe.",
        },
      },
      {
        label: {
          en: "I am friendly to others.",
          de: "Ich bin freundlich zu anderen.",
        },
      },
      { label: { en: "I am sociable.", de: "Ich bin sozial." } },
      { label: { en: "I am confident.", de: "Ich bin selbstbewusst." } },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I dominate discussions.",
          de: "Ich dominiere in Diskussionen.",
        },
      },
      {
        label: {
          en: "I often speak with enthusiasm.",
          de: "Ich spreche oft mit Enthusiasmus.",
        },
      },
      { label: { en: "I rarely get upset.", de: "Ich werde selten wütend." } },
      {
        label: {
          en: "I like to plan ahead.",
          de: "Ich plane gerne im Voraus.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I want something to go forward.",
          de: "Ich will sehen, dass etwas weitergeht.",
        },
      },
      {
        label: {
          en: "I am usually easy-going.",
          de: "Ich bin normalerweise entspannt.",
        },
      },
      {
        label: {
          en: "I am willing to speak my mind.",
          de: "Ich bin gewillt meine Meinung zu sagen.",
        },
      },
      {
        label: {
          en: "I don't like to argue.",
          de: "Ich mag es nicht zu streiten.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I want to remain neutral in conflicts.",
          de: "Ich möchte in Konflikten neutral bleiben.",
        },
      },
      {
        label: {
          en: "Others enjoy being with me.",
          de: "Andere haben mich gerne um sich.",
        },
      },
      { label: { en: "I am energetic.", de: "Ich bin energetisch." } },
      {
        label: {
          en: "I also work disciplined alone.",
          de: "Ich kann auch allein diszipliniert arbeiten.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I sometimes dodge decision-making.",
          de: "Manchmal gehe ich Entscheidungen aus dem Weg.",
        },
      },
      {
        label: {
          en: "I like to further develop the tried and tested.",
          de: "Ich möchte Bewährtes weiterentwickeln.",
        },
      },
      {
        label: {
          en: "It is important to me, what other people think.",
          de: "Es ist mir wichtig, was andere Leute denken.",
        },
      },
      {
        label: {
          en: "I don't do things by halves.",
          de: "Ich mache keine halben Sachen.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I have a lot of friends.",
          de: "Ich habe viele Freunde.",
        },
      },
      {
        label: {
          en: "I am a critical thinker.",
          de: "Ich bin ein kritischer Denker.",
        },
      },
      {
        label: {
          en: "I like to influence others.",
          de: "Ich mag es andere zu beeinflussen.",
        },
      },
      {
        label: {
          en: "I want to avoid embarrassment.",
          de: "Ich möchte Peinlichkeiten vermeiden.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I like to take the action.",
          de: "Ich übernehme gerne die Führung.",
        },
      },
      {
        label: {
          en: "I understand the feelings of others.",
          de: "Ich verstehe Gefühle anderer.",
        },
      },
      { label: { en: "I have lots of fun.", de: "Ich habe viel Spaß." } },
      {
        label: {
          en: "I have high expectations of myself.",
          de: "Ich habe hohe Erwartungen an mich selbst.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I aim for immediate results.",
          de: "Ich möchte sofortige Ergebnisse.",
        },
      },
      { label: { en: "I am precise.", de: "Ich bin genau." } },
      {
        label: {
          en: "I am ready to follow instructions.",
          de: "Ich bin bereit dazu Anweisungen zu befolgen.",
        },
      },
      {
        label: { en: "I am easily excited.", de: "Ich bin leicht aufgeregt." },
      },
    ],
  },
  {
    answers: [
      {
        label: { en: "I am a good analyst.", de: "Ich bin ein guter Analyst." },
      },
      {
        label: {
          en: "I make no demands on others.",
          de: "Ich stelle keine Anforderungen an andere.",
        },
      },
      {
        label: {
          en: "I develop my goals spontaneously.",
          de: "Ich entwickle meine Ziele spontan.",
        },
      },
      {
        label: {
          en: "I recognise where there is a need for change.",
          de: "Ich erkenne wo Änderungsbedarf besteht.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I don't like to draw attention to myself.",
          de: "Ich mag es nicht, die Aufmerksamkeit auf mich zu ziehen.",
        },
      },
      {
        label: {
          en: "I love predictable environments. ",
          de: "Ich mag berechenbare Umgebungen.",
        },
      },
      {
        label: {
          en: "I tell others what I am doing.",
          de: "Ich sage anderen was ich mache.",
        },
      },
      {
        label: {
          en: "I do things on the side.",
          de: "Ich mache Dinge nebenbei.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I think about what is reasonable.",
          de: "Ich denke darüber nach was sinnvoll ist.",
        },
      },
      {
        label: {
          en: "I don't like to cause problems.",
          de: "Ich verursache ungern Probleme.",
        },
      },
      {
        label: {
          en: "I like to liven things up.",
          de: "Ich bringe gern Leben in die Sache.",
        },
      },
      { label: { en: "I am brave.", de: "Ich bin mutig." } },
    ],
  },
  {
    answers: [
      {
        label: { en: "I like to be precise.", de: "Ich mag es genau zu sein." },
      },
      {
        label: {
          en: "I am a patient listener.",
          de: "Ich bin ein geduldiger Zuhörer.",
        },
      },
      {
        label: {
          en: "I am decisive.",
          de: "Ich treffe schnell Entscheidungen.",
        },
      },
      { label: { en: "I am talkative.", de: "Ich bin gesprächig." } },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I attach importance to accuracy.",
          de: "Genauigkeit ist mir sehr wichtig.",
        },
      },
      {
        label: {
          en: "I give a lot of information to support my statements.",
          de: "Ich biete viel Information, um meine Aussagen zu unterstützen.",
        },
      },
      {
        label: {
          en: "I pay attention to how I talk to people.",
          de: "Ich passe auf, wie ich mit anderen spreche.",
        },
      },
      {
        label: {
          en: "I can easily get into conversations.",
          de: "Ich fange sehr leicht Gespräche an.",
        },
      },
    ],
  },
  {
    answers: [
      { label: { en: "I catch on with others.", de: "Ich verstehe andre." } },
      {
        label: {
          en: "I am usually happy.",
          de: "Ich bin normalerweise glücklich.",
        },
      },
      {
        label: {
          en: "I say what I am thinking.",
          de: "Ich sage was ich denke.",
        },
      },
      {
        label: {
          en: "I have high expectations.",
          de: "Ich habe hohe Erwartungen.",
        },
      },
    ],
  },
  {
    answers: [
      { label: { en: "I am satisfied.", de: "Ich bin zufrieden." } },
      {
        label: {
          en: "I keep my things in order.",
          de: "Ich halte meine Sachen in Ordnung.",
        },
      },
      { label: { en: "I do what I want.", de: "Ich mache was ich will." } },
      {
        label: {
          en: "I want to have many friends.",
          de: "Ich will viele Freunde haben.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I am calm and relaxed.",
          de: "Ich bin ruhig und entspannt.",
        },
      },
      {
        label: {
          en: "I reflect about problems.",
          de: "Ich reflektiere meine eigenen Probleme.",
        },
      },
      {
        label: {
          en: "I think about what others think of me.",
          de: "Ich denke darüber nach, wie andere mich wohl finden.",
        },
      },
      {
        label: {
          en: "I want to get things done immediately.",
          de: "Ich möchte Dinge sofort erledigen.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I hate not having enough time for quality work.",
          de: "Ich mag es nicht, keine Zeit für Qualitätsarbeit zu haben.",
        },
      },
      {
        label: {
          en: "I go straight to the action on projects.",
          de: "Bei Projekten lege ich sofort los.",
        },
      },
      {
        label: {
          en: "I easily find new friends.",
          de: "Ich finde leicht neue Freunde.",
        },
      },
      {
        label: {
          en: "I am happy and carefree.",
          de: "Ich bin glücklich und sorglos.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I approach strangers easily.",
          de: "Ich gehe leicht auf Fremde zu.",
        },
      },
      {
        label: {
          en: "I don't like to give in.",
          de: "Ich gebe nicht gern auf.",
        },
      },
      {
        label: {
          en: "I want to have a chance to review the facts.",
          de: "Ich möchte Gelegenheit haben, die Fakten zu prüfen.",
        },
      },
      {
        label: {
          en: "I quickly apologize.",
          de: "Ich entschuldige mich schnell.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "Details are important to me.",
          de: "Details sind mir wichtig.",
        },
      },
      {
        label: {
          en: "My mind is made up quickly.",
          de: "Ich bilde mir schnell Meinungen.",
        },
      },
      {
        label: {
          en: "I like working behind the scenes.",
          de: "Ich arbeite gern hinter den Kulissen.",
        },
      },
      { label: { en: "I like to share jokes.", de: "Ich mache gern Witze." } },
    ],
  },
  {
    answers: [
      { label: { en: "I am determined.", de: "Ich bin entschlossen." } },
      { label: { en: "I am optimistic.", de: "Ich bin optimistisch." } },
      {
        label: {
          en: "I am a patient listener.",
          de: "Ich bin ein geduldiger Zuhörer.",
        },
      },
      {
        label: {
          en: "I am good at working alone.",
          de: "Ich kann gut allein arbeiten.",
        },
      },
    ],
  },
  {
    answers: [
      { label: { en: "I like to have fun.", de: "Ich will Spaß haben." } },
      {
        label: {
          en: "Others think I am strong.",
          de: "Andere halten mich für stark.",
        },
      },
      {
        label: { en: "I like to help others.", de: "Ich helfe gern anderen." },
      },
      {
        label: {
          en: "I like to do my things properly.",
          de: "Ich halte meine Sachen gern in Ordnung.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I take care to say the right thing.",
          de: "Ich passe auf, dass ich das richtige sage.",
        },
      },
      {
        label: {
          en: "I want to avoid confrontations.",
          de: "Ich möchte Konfrontationen vermeiden.",
        },
      },
      {
        label: {
          en: "I like to take responsibility.",
          de: "Ich übernehme gern Verantwortung.",
        },
      },
      {
        label: {
          en: "I think about others before I decide.",
          de: "Ich denke an andere, bevor ich entscheide.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I am the first to act.",
          de: "Ich bin der erste der handelt.",
        },
      },
      {
        label: {
          en: "I like to know the rules.",
          de: "Ich kenne gern die Regeln.",
        },
      },
      {
        label: {
          en: "I like to be in groups.",
          de: "Ich mag Gruppenarbeiten.",
        },
      },
      { label: { en: "I let others lead.", de: "Ich lasse andere führen." } },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I ease tension with words.",
          de: "Ich lockere Spannung mit Worten auf.",
        },
      },
      {
        label: {
          en: "I stand by my decisions.",
          de: "Ich stehe zu meinen Entscheidungen.",
        },
      },
      {
        label: {
          en: "I think carefully about my problems.",
          de: "Ich denke sorgfältig über meine Probleme nach.",
        },
      },
      {
        label: {
          en: "I have lots of different friends.",
          de: "Ich habe viele verschiedene Freunde.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I often agree with others.",
          de: "Ich stimme oft anderen zu.",
        },
      },
      { label: { en: "I show my feelings.", de: "Ich zeige meine Gefühle." } },
      {
        label: {
          en: "I pay attention to details.",
          de: "Ich achte auf Details.",
        },
      },
      {
        label: {
          en: "I like to make the rules.",
          de: "Ich stelle gern die Regeln auf.",
        },
      },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I want to have fun at work.",
          de: "Ich will Spaß bei der Arbeit haben.",
        },
      },
      {
        label: {
          en: "I want to be comfortable.",
          de: "Ich will mich wohlfühlen.",
        },
      },
      {
        label: {
          en: "I like to do things right.",
          de: "Ich will Dinge richtig machen.",
        },
      },
      { label: { en: "I feel strong.", de: "Ich fühle mich stark." } },
    ],
  },
  {
    answers: [
      {
        label: {
          en: "I often give an immediate feedback.",
          de: "Ich gebe sofort Feedback.",
        },
      },
      { label: { en: "I don't like to wait.", de: "Ich warte nicht gern." } },
      {
        label: {
          en: "I accept the good and bad times.",
          de: "Ich akzeptiere gute und schlechte Zeiten.",
        },
      },
      { label: { en: "I often work alone.", de: "Ich arbeite oft allein." } },
    ],
  },
];
