import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  table: {
    "& tr:nth-child(odd)": {
      background: theme.palette.background.default,
    },
  },
  header: {
    background: `${theme.palette.grey["A100"]} !important`,
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
  },
}));
