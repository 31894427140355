function sum(data: number[]) {
  return data.reduce((a, n) => a + n, 0);
}
/**
 * **TODO check if this works fine**
 * @param {number[]} data an array of numbers, where the number can be 0 (no) or 1 (yes)
 *
 * @returns a tuple of the 5 categories and their count
 */
export function calculatePart3(data: number[]) {
  // [scala1_special, scala1_total - scala1_special, scala2_total, scala3_special, scala3_total - scala3_special]
  const result = [0, 0, 0, 0, 0];

  const scala1 = data.slice(part3groups.scala1[0], part3groups.scala1[1] + 1);
  const scala2 = data.slice(part3groups.scala2[0], part3groups.scala2[1] + 1);
  const scala3 = data.slice(part3groups.scala3[0], part3groups.scala3[1] + 1);

  const scala1Special = scala1.filter((_, i) =>
    specialIndices.scala1.includes(i),
  );

  result[0] = sum(scala1Special);
  result[1] = sum(scala1) - result[0];

  result[2] = sum(scala2);

  const scala3Special = scala3.filter((_, i) =>
    specialIndices.scala3.includes(i),
  );

  result[3] = sum(scala3Special);
  result[4] = sum(scala3) - result[3];

  return result;
}

const part3groups = {
  scala1: [0, 59],
  scala2: [60, 103],
  scala3: [104, 142],
};

// starting with 0
const specialIndices = {
  scala1: [
    1,
    2,
    5,
    7,
    8,
    10,
    14,
    15,
    17,
    18,
    19,
    20,
    23,
    24,
    25,
    27,
    28,
    32,
    34,
    36,
    37,
    39,
    41,
    42,
    44,
    45,
    46,
    48,
    55,
    56,
  ],
  scala3: [3, 4, 5, 6, 8, 11, 12, 15, 21, 23, 27, 29, 30, 31, 32, 34, 36, 38],
};
