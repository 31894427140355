const questionMapping = [
  ["s", "i", "g", "d"],
  ["g", "s", "i", "d"],
  ["d", "i", "s", "g"],
  ["n", "i", "d", "s"],
  ["s", "i", "d", "g"],
  ["s", "n", "n", "d"],
  ["i", "g", "d", "s"],
  ["d", "s", "i", "g"],
  ["d", "g", "s", "i"],
  ["g", "s", "i", "n"],
  ["s", "g", "i", "d"],
  ["g", "s", "i", "d"],
  ["g", "s", "d", "i"],
  ["g", "n", "s", "i"],
  ["s", "i", "d", "g"],
  ["n", "g", "d", "i"],
  ["n", "g", "s", "d"],
  ["g", "d", "i", "n"],
  ["i", "d", "g", "s"],
  ["g", "d", "s", "i"],
  ["d", "i", "s", "g"],
  ["i", "d", "s", "g"],
  ["n", "n", "d", "s"],
  ["d", "g", "i", "s"],
  ["n", "d", "g", "i"],
  ["s", "i", "g", "d"],
  ["i", "s", "g", "d"],
  ["n", "n", "s", "g"],
];

const resultMapping = {
  d: [
    // [from, to] (including)
    [-25, -10],
    [-9, -7],
    [-6, -4],
    [-3, -2],
    [-1, 2],
    [3, 6],
    [7, 25],
  ],
  i: [
    // [from, to] (including)
    [-24, -9],
    [-8, -6],
    [-5, -4],
    [-3, -2],
    [-1, 1],
    [2, 4],
    [5, 24],
  ],
  s: [
    // [from, to] (including)
    [-25, -9],
    [-8, -6],
    [-5, -4],
    [-3, -2],
    [-1, 1],
    [2, 5],
    [6, 25],
  ],
  g: [
    // [from, to] (including)
    [-25, -1],
    [0, 2],
    [3, 5],
    [6, 7],
    [8, 10],
    [11, 13],
    [14, 25],
  ],
};

const disg = ["d", "i", "s", "g"] as const;

/**
 *
 * @param {[number, number, number, number]} values
 */
function mapResult(values: [number, number, number, number]) {
  const result = [];
  for (let i in disg) {
    const mapping = resultMapping[disg[i]];

    const r = mapping.findIndex(
      ([from, to]) => from <= values[i] && to >= values[i],
    );

    if (r === -1) {
      throw Error("There's a bug!");
    }
    result.push(r + 1);
  }
  return result;
}

/**
 * @param {[number, number][]} data an array of a number tuple [mostApplicable, leastApplicable], where the values can be 0-3 (the index of the selected question)
 *
 * @returns a tuple of the category for [d, i, s, g]
 */
export function calculatePart2(data: [number, number][]) {
  const mapped = data.map(([most, least], i) => {
    const mappingRow = questionMapping[i];
    return [mappingRow[most], mappingRow[least]];
  });
  const count = (symbol: string) =>
    mapped.reduce(
      ([mostCount, leastCount], [most, least]) => [
        mostCount + +(symbol === most),
        leastCount + +(symbol === least),
      ],
      /* [mostCount, leastCount] */ [0, 0],
    );

  const resultCounts = disg.map(count);

  const sumedUp = resultCounts.map(([most, least]) => most - least);

  const result = mapResult(sumedUp as [number, number, number, number]);

  return result;
}
