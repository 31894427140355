import React, { memo } from "react";
import { useStyles } from "./index.styles";
import {
  Button,
  FormControlLabel,
  Radio,
  TextField,
  RadioGroup,
  FormControl,
  FormLabel,
  Typography,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import useLocalStorage from "@rehooks/local-storage";
import { Pages } from "../Pages";
import { useTranslation, Translation } from "../../../hooks/translation";

interface Props {
  onGeneralFinish: () => void;
}

const translations: Translation<Record<
  | "tell_us"
  | "note_anonymous"
  | "name.label"
  | "gender.label"
  | "gender.value.male"
  | "gender.value.female"
  | "gender.value.other"
  | "age.label"
  | "startButton",
  string
>> = {
  en: {
    "name.label": "Name",
    "age.label": "Age",
    "gender.label": "Gender",
    "gender.value.female": "Female",
    "gender.value.male": "Male",
    "gender.value.other": "Other",
    tell_us: "Tell us something about you!",
    note_anonymous:
      "You don't have to tell us anything if you don't want to. You can skip the inputs and start the test.",
    startButton: "Continue",
  },
  de: {
    "name.label": "Name",
    "age.label": "Alter",
    "gender.label": "Geschlecht",
    "gender.value.female": "Weiblich",
    "gender.value.male": "Männlich",
    "gender.value.other": "Anderes",
    tell_us: "Erzähl etwas von dir!",
    note_anonymous:
      "Wenn du uns lieber nichts erzählen möchtest, ist das auch ok. Überspringe einfach die Eingabefelder und drücke auf den Knopf unten.",
    startButton: "Weiter",
  },
};

const initialValue = {
  name: "",
  age: "",
  gender: "other",
};

function General({ onGeneralFinish }: Props) {
  const classes = useStyles();

  const [data, setData] = useLocalStorage(Pages.General, initialValue);

  const i18n = useTranslation(translations);

  if (!data) {
    // can't use localStorage
    return null;
  }

  const handleChange = (key: keyof typeof initialValue) => (
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => setData({ ...data, [key]: evt.target.value });

  return (
    <>
      <Typography variant="h5">{i18n.tell_us}</Typography>
      <Alert severity="info">{i18n.note_anonymous}</Alert>
      <div>
        <div className={classes.inputs}>
          <TextField
            label={i18n["name.label"]}
            variant="filled"
            name="name"
            value={data.name}
            onChange={handleChange("name")}
          />
          <TextField
            style={{ width: 96, marginLeft: 4 }}
            label={i18n["age.label"]}
            name="age"
            type="number"
            variant="filled"
            value={data.age}
            onChange={handleChange("age")}
          />
        </div>
        <div className={classes.inputs}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{i18n["gender.label"]}</FormLabel>

            <RadioGroup
              aria-label="gender"
              name="gender"
              value={data.gender}
              onChange={handleChange("gender")}
            >
              <FormControlLabel
                label={i18n["gender.value.male"]}
                value="male"
                control={<Radio color="primary" />}
              />

              <FormControlLabel
                label={i18n["gender.value.female"]}
                value="female"
                control={<Radio color="primary" />}
              />

              <FormControlLabel
                label={i18n["gender.value.other"]}
                value="other"
                control={<Radio color="primary" />}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className={classes.inputs}>
        <Button onClick={onGeneralFinish} variant="contained" color="primary">
          {i18n.startButton}
        </Button>
      </div>
    </>
  );
}

export default memo(General);
