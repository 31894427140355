import { makeStyles } from "@material-ui/core";
import { primary } from "../../../theme";

export const useStyles = makeStyles(theme => ({
  root: {
    display: "grid",
    gridTemplateColumns: "48px 1fr",
    backgroundColor: primary[200],
    transition: "background-color 0.1s linear, border-radius 0.2s linear",
    cursor: "pointer",
    "&:hover, &$selected": {
      boxShadow: "0px 4px 8px rgba(34, 35, 58, 0.2)",
      zIndex: 2,
    },
    "&:hover": {
      backgroundColor: primary[100] + " !important",
    },
    "&:active, &:focus, &$selected": {
      backgroundColor: primary["A100"],
    },
  },
  odd: {
    backgroundColor: primary[300],
    "& $value": {},
  },
  value: {
    display: "inline-flex",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.05)",
  },
  selected: {
    borderRadius: theme.spacing(3, 0, 0, 3),
    "& $value": {
      borderRadius: theme.spacing(3, 0, 0, 3),
    },
  },
  label: {
    flexGrow: 1,
    display: "inline-flex",
    alignItems: "center",

    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
