import { ButtonConfig } from "./AnswerButton";

export const buttonConfigs: ButtonConfig[] = [
  {
    label: { en: "Strongly agree", de: "Stimme sehr zu" },
    value: 7,
  },
  {
    label: { en: "Agree", de: "Stimme zu" },
    value: 6,
  },
  {
    label: { en: "Slightly agree", de: "Stimme ein bisschen zu" },
    value: 5,
  },
  {
    label: { en: "Neutral", de: "Neutral" },
    value: 4,
  },
  {
    label: { en: "Slightly disagree", de: "Stimme ein bisschen nicht zu" },
    value: 3,
  },
  {
    label: { en: "Disagree", de: "Stimme nicht zu" },
    value: 2,
  },
  {
    label: { en: "Strongly disagree", de: "Stimme gar nicht zu" },
    value: 1,
  },
];
