import { makeStyles } from "@material-ui/core";
import { primary } from "../../theme";

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    "@media (max-width: 768px)": {
      alignItems: "start",
    },
  },
  card: {
    minWidth: "50%",
    maxWidth: "768px",
    borderRadius: theme.spacing(3),
    boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.4)",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0.5),
    background: `linear-gradient(34deg, ${primary[900]} 0%, ${primary.A200} 29%, ${primary[900]} 92%)`,

    "@media (max-width: 768px)": {
      background: "none",
      boxShadow: "none",
      borderRadius: 0,
      padding: 0,
      width: "100%",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(2.5),
    background: "rgba(255,255,255,0.9)",

    "@media (max-width: 768px)": {
      background: "none",
      borderRadius: 0,
      padding: theme.spacing(0.5),
    },
  },
}));
