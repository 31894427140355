import { Translation } from "../../hooks/translation";

export enum Pages {
  Introduction = "introduction",
  General = "general",
  Part1 = "part1",
  Part2 = "part2",
  Part3 = "part3",
  Finish = "finish",
}

export const pageTranslations: Translation<Record<Pages, string>> = {
  en: {
    [Pages.Introduction]: "Introduction",
    [Pages.General]: "General",
    [Pages.Part1]: "Part 1",
    [Pages.Part2]: "Part 2",
    [Pages.Part3]: "Part 3",
    [Pages.Finish]: "Result",
  },
  de: {
    [Pages.Introduction]: "Einleitung",
    [Pages.General]: "Generell",
    [Pages.Part1]: "Teil 1",
    [Pages.Part2]: "Teil 2",
    [Pages.Part3]: "Teil 3",
    [Pages.Finish]: "Ergebnis",
  },
};
