import React, { useMemo, useRef } from "react";

import { CSVLink } from "react-csv";

import { generate as getUniqueID } from "shortid";

import { useHistory } from "react-router-dom";
import { Data, prepareData } from "./prepare";
import { getPageRoute } from "..";
import { Pages } from "../Pages";
import useLocalStorage, { deleteFromStorage } from "@rehooks/local-storage";
import { Part1ResultDatum } from "../Part1/types";
import { Part2ResultDatum } from "../Part2/types";
import { Part3ResultDatum } from "../Part3/types";

import { Button } from "@material-ui/core";

import DownloadIcon from "@material-ui/icons/CloudDownload";
import MailIcon from "@material-ui/icons/Mail";

import { useStyles } from "./index.styles";
import { GeneralResult } from "../General/types";
import {
  Translation,
  useTranslation,
  useLanguage,
} from "../../../hooks/translation";
import { Heart } from "./Heart";

const translations: Translation<Record<
  "finished" | "yourHelp" | "download" | "send" | "reset" | "mail",
  string
>> = {
  en: {
    finished: "That's it, you've finished the test!",
    yourHelp: "Your help in this study is very appreciated",
    download: "Download test result data",
    send: "Please send us your test data to get an evaluation of r test!",
    reset: "Reset test",
    mail: "Send per e-mail (Recommended)",
  },
  de: {
    finished: "Das wars, du hast den Test beendet!",
    yourHelp: "Danke für deine Hilfe",
    download: "Testresultat herunterladen",
    send:
      "Bitte sende uns deine Testdaten um eine Auswertung von uns zu bekommen!",
    reset: "Test zurücksetzen",
    mail: "Als E-Mail senden (Empfohlen)",
  },
};

interface Props {}

export interface Base64Data {
  general: GeneralResult;
  part1: Part1ResultDatum[];
  part2: [number, number][];
  part3: number[];
  id: string;
}

export function Finish(props: Props) {
  const uniqueID = useRef(getUniqueID()).current;

  const classes = useStyles();

  const { language } = useLanguage();

  const i18n = useTranslation(translations);

  const { push } = useHistory();

  const handleReset = () => {
    deleteFromStorage(Pages.General);
    deleteFromStorage(Pages.Part1);
    deleteFromStorage(Pages.Part2);
    deleteFromStorage(Pages.Part3);
    push(getPageRoute(Pages.Introduction));
  };

  const [general] = useLocalStorage<GeneralResult>(Pages.General);
  const [part1] = useLocalStorage<Part1ResultDatum[]>(Pages.Part1);
  const [part2] = useLocalStorage<Part2ResultDatum[]>(Pages.Part2);
  const [part3] = useLocalStorage<Part3ResultDatum[]>(Pages.Part3);

  const data = { general, part1, part2, part3 } as Data;

  const base64Data = useMemo(() => {
    const result: Base64Data = {
      general: data.general,
      part1: data.part1,
      // [mostApplicable, leastApplicable][]
      part2: data.part2.map((d) => [d.mostApplicable, d.leastApplicable]),
      part3: data.part3.map((a) => +a.applicable),
      id: uniqueID,
    };

    return btoa(JSON.stringify(result));
  }, [data, uniqueID]);

  const mailSubject = `Personality Test - ${uniqueID}`;
  const mailBody = [
    "DO NOT EDIT!",
    "----------------------",
    `Language: ${language}`,
    `ID: ${uniqueID}`,
    "Data:",
    base64Data,
    "----------------------",
  ].join("\n");

  const mailLink = `mailto:nicime2015@gmail.com?subject=${encodeURI(
    mailSubject,
  )}&body=${encodeURI(mailBody)}`;

  return (
    <>
      <p>
        {i18n.finished}
        <br />
        <span className={classes.appreciate}>
          {i18n.yourHelp} <Heart color="secondary" />
        </span>
        <br />
        {i18n.send}
      </p>

      <Button
        href={mailLink}
        variant="contained"
        color="primary"
        className={classes.csv}
        endIcon={<MailIcon />}
      >
        {i18n.mail}
      </Button>

      <CSVLink
        enclosingCharacter=""
        filename="personality-test.csv"
        data={prepareData(data)}
        className={classes.csv}
      >
        <Button variant="contained" color="primary" endIcon={<DownloadIcon />}>
          <span>{i18n.download}</span>
        </Button>
      </CSVLink>

      <Button
        size="small"
        onClick={handleReset}
        variant="outlined"
        color="primary"
      >
        {i18n.reset}
      </Button>
    </>
  );
}
