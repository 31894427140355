import React, { useState } from "react";

import {
  Button,
  ButtonGroup,
  useMediaQuery,
  Theme,
  Collapse,
  IconButton,
  Divider,
} from "@material-ui/core";
import { useStyles } from "./index.styles";
import { Pages } from "../../pages/Test/Pages";

import MenuIcon from "@material-ui/icons/Menu";
import { CustomLink } from "./LinkButton";
import { useProgress } from "../../hooks/progress";
import { useLanguage } from "../../hooks/translation";

export default function Layout({ children }: React.PropsWithChildren<{}>) {
  const { language, setLanguage } = useLanguage();

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(open => !open);

  const classes = useStyles();

  const progress = useProgress();

  const navButtons = (
    <div className={classes.nav}>
      <CustomLink
        isDesktop={isDesktop}
        page={Pages.Introduction}
        isLocked={false}
      />
      <CustomLink
        isDesktop={isDesktop}
        highlighted
        page={Pages.General}
        isLocked={!progress[0]}
      />
      <CustomLink
        isDesktop={isDesktop}
        highlighted
        page={Pages.Part1}
        isLocked={!progress[1]}
      />
      <CustomLink
        isDesktop={isDesktop}
        highlighted
        page={Pages.Part2}
        isLocked={!progress[2]}
      />
      <CustomLink
        isDesktop={isDesktop}
        highlighted
        page={Pages.Part3}
        isLocked={!progress[3]}
      />
      {/* <CustomLink page={Pages.Finish} isLocked={false} /> */}
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {isDesktop ? (
          navButtons
        ) : (
          <IconButton onClick={toggleMenu}>
            <MenuIcon />
          </IconButton>
        )}
        <div>
          <ButtonGroup color="primary" variant="contained">
            <Button
              className={classes.button}
              classes={{ disabled: classes.disabledButton }}
              disabled={language === "de"}
              onClick={() => setLanguage("de")}
            >
              Deutsch
            </Button>
            <Button
              className={classes.button}
              classes={{ disabled: classes.disabledButton }}
              disabled={language === "en"}
              onClick={() => setLanguage("en")}
            >
              English
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <Collapse in={menuOpen}>{navButtons}</Collapse>
      <Divider />

      {children}
    </div>
  );
}
