import React, { memo } from "react";
import { SingleQuestion } from "./data";
import { useTranslation } from "../../../hooks/translation";
import {
  Radio,
  TableRow,
  TableCell,
  FormControlLabel,
  Chip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { useHotkeys } from "react-hotkeys-hook";

interface Props {
  question: SingleQuestion;
  mostApplicable: number | undefined;
  leastApplicable: number | undefined;
  index: number;
  setMostApplicable: (index: number) => void;
  setLeastApplicable: (index: number) => void;
}

function Row({
  question,
  mostApplicable,
  leastApplicable,
  setMostApplicable,
  setLeastApplicable,
  index,
}: Props) {
  const label = useTranslation(question.label);

  const mostApplicableDisabled = index === leastApplicable;
  const leastApplicableDisabled = index === mostApplicable;
  const handleMostApplicable = () =>
    !mostApplicableDisabled && setMostApplicable(index);
  const handleLeastApplicable = () =>
    !leastApplicableDisabled && setLeastApplicable(index);
  const mostApplicableHotkey = String(index * 2 + 1);
  const leastApplicableHotkey = String(index * 2 + 2);

  useHotkeys(mostApplicableHotkey, handleMostApplicable, [
    handleMostApplicable,
  ]);
  useHotkeys(leastApplicableHotkey, handleLeastApplicable, [
    handleLeastApplicable,
  ]);

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const mostApplicableLabel = isDesktop && (
    <Chip label={mostApplicableHotkey} variant="outlined" size="small" />
  );

  const leastApplicableLabel = isDesktop && (
    <Chip label={leastApplicableHotkey} variant="outlined" size="small" />
  );

  return (
    <TableRow>
      <TableCell>
        <span>{label}</span>
      </TableCell>
      <TableCell align="center">
        <FormControlLabel
          label={mostApplicableLabel}
          control={
            <Radio
              name="most"
              color="primary"
              checked={index === mostApplicable}
              disabled={mostApplicableDisabled}
              onChange={handleMostApplicable}
            />
          }
        />
      </TableCell>
      <TableCell align="center">
        <FormControlLabel
          label={leastApplicableLabel}
          control={
            <Radio
              name="least"
              color="secondary"
              checked={index === leastApplicable}
              disabled={leastApplicableDisabled}
              onChange={handleLeastApplicable}
            />
          }
        />
      </TableCell>
    </TableRow>
  );
}

export default memo(Row);
