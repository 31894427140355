import React, { memo } from "react";
import { styled } from "@material-ui/core";

import { animated } from "react-spring";

interface Props {
  style: any;
  children: any;
}

const StyledDiv = styled(animated.div)({ willChange: "opacity, translateX" });

export const AnimationWrapper = memo<Props>(({ style, children }) => (
  <StyledDiv style={style}>{children}</StyledDiv>
));
